$xs: 320px;
$sm: 480px;
$md: 767px;
$lg: 1024px;
$xl: 1920px;

@import 'variables.scss';

@media (min-width: $xs) and (max-width: $sm) and (orientation: portrait) {

    /*===============================================================
    Home
    ===============================================================*/

    .privacy {
        margin-top: 20%;

        .privacy-body {

            p,
            li {
                font-size: 16px;
            }

            h5 {
                font-size: 18px;
            }
        }
    }

    .navbar {
        .header-info {
            display: none;
        }

        .header {
            padding: 5% 0;

            .header-links {
                display: none;
            }

            .menu {
                display: block;
            }

            .header-logo {
                img {
                    width: 200%;
                    margin-left: 5%;
                }
            }
        }
    }

    .title,
    .page-title {
        h4 {
            font-size: 5vw;
            text-transform: uppercase;
        }
    }

    .banner {
        margin-top: 20%;

        .carousel-control-next-icon,
        .carousel-control-prev-icon {
            width: 20px;
            height: 20px;
        }

        .carousel {
            height: 25vh;

            .carousel-inner {
                .carousel-item {
                    height: 28vh;
                    margin-top: -5%;

                    h5 {
                        font-size: 4vw;
                        width: 40%;
                        top: 37%;
                        line-height: 150%;
                    }

                    img {
                        width: 150px !important;
                        height: 150px;
                        left: 5%;
                        padding: 5%;
                        border-radius: 0;
                    }
                }

            }
        }
    }

    .investment-philosophy {
        p {
            font-size: $xs-p-font;
        }

        .investment-content {
            flex-wrap: wrap;

            .investment-philosophy-body {
                width: 100% !important;

                .i-p-card {
                    padding: 1% 3%;

                    h4 {
                        font-size: $xs-p-font;
                        margin-top: 5%;
                    }

                    .i-img {
                        img {
                            width: 30px;
                            height: 30px;
                        }
                    }
                }
            }

            .child {
                width: 100% !important;

                div {
                    h4 {
                        font-size: 4vw;
                    }

                    p {
                        font-size: $xs-p-font;
                    }
                }
            }
        }
    }

    .what-we-offer {
        .what-we-offer-body {
            flex-wrap: wrap;

            .what-card {
                width: 100%;
                flex-direction: column;
                padding: 7% 3%;
                // height: 35vh;

                .what-card-img {
                    margin-bottom: 10%;

                    img {
                        margin: auto;
                        display: block;
                        width: 50px;
                        height: 50px;
                    }
                }

                .what-card-text {
                    height: 100%;

                    h3 {
                        font-size: 4vw;
                    }

                    p {
                        font-size: $xs-p-font;
                    }

                    a {
                        font-size: $xs-p-font;
                        right: -6%;
                        bottom: -30%;
                        border-radius: 5px 0 0 0;
                    }
                }
            }
        }
    }

    .superior-tech-platform {
        .superior-tech-platform-body {
            .s-t-p-card {
                flex-direction: column;
                width: 100%;

                img {
                    width: 40px;
                    height: 40px;
                    display: block;
                    margin: 0 auto;
                }

                p {
                    margin-top: 10%;
                    font-size: $xs-p-font;
                    margin-bottom: 0;
                    height: 100%;
                }
            }
        }
    }

    .achievements {
        .achievements-body {
            padding: 5%;

            .a-card {
                width: calc(100% /2.2);

                svg {

                    width: 35px;
                    height: 35px;
                }

                h4 {
                    font-size: 3.5vw;
                    margin-top: 10%;
                }

                p {
                    font-size: $xs-p-font;
                }
            }
        }
    }

    .about {
        flex-wrap: wrap;

        .about-img {
            width: 100%;

            .image-overlay {
                display: none;
            }
        }

        .about-caption {
            width: 100%;
            margin-top: 10%;

            h3 {
                font-size: 4vw;
            }

            p {
                font-size: $xs-p-font;
            }
        }
    }

    .home-testimonial {
        padding: 5% 0;

        .carousel {
            padding: 0 3%;

            .carousel-slider {
                .control-arrow {
                    height: 100%;
                }
            }

            .control-next.control-arrow:before {
                border-top: 8px solid transparent;
                border-left: 8px solid $copper;
                border-bottom: 8px solid transparent;
            }

            .control-prev.control-arrow:before {
                border-top: 8px solid transparent;
                border-right: 8px solid $copper;
                border-bottom: 8px solid transparent;
            }
        }

        .testimonial-body {
            flex-direction: column;
            height: 100%;
            padding: 0 5%;
            margin-bottom: 10%;

            .testimonial {
                width: 100%;
                margin-top: 5%;
                height: 40vh;

                &:nth-child(2) {
                    display: none;
                }

                .source {
                    p {
                        font-size: 2.5vw;
                        margin-top: 7%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 8;
                        /* number of lines to show */
                        line-clamp: 8;
                        -webkit-box-orient: vertical;
                    }
                }

                .image {


                    .clip {
                        width: 10px;
                        height: 20px;

                        &::before {
                            height: 10px;
                            width: 5px;
                        }

                        &:after {
                            height: 20px;
                            width: 10px;
                        }
                    }

                    img {
                        width: 50%;
                        right: -40%;
                        position: relative;
                    }
                }

                .quote {
                    width: 20px;
                    height: 20px;
                    font-size: 7vw;
                }

                .source-text {
                    span {
                        font-size: 3vw;
                    }

                    p {
                        font-size: 2.5vw;

                    }
                }
            }
        }
    }

    .home-blog {
        .home-blog-body {
            flex-direction: column;

            .home-blog-card {
                width: 100%;
                margin-bottom: 5%;
            }
        }
    }

    footer {

        .last {
            font-size: 2.5vw;
            margin-top: 5%;
        }

        .footer-body {
            // flex-direction: column;
            flex-wrap: wrap;
            padding: 5%;

            .footer-about,
            .footer-links,
            .footer-contact,
            .footer-map {
                width: 100%;
            }

            .footer-about {
                width: 100%;

                h2 {
                    font-size: 4vw;
                    margin-bottom: 5%;
                }

                p {
                    font-size: $xs-p-font;
                }
            }

            .footer-map {
                iframe {
                    height: 50vh;
                }
            }

            .footer-links {
                width: 48%;

                h4 {
                    font-size: 4vw;
                    margin-bottom: 5%;
                }

                .footer-links-content {
                    a {
                        font-size: $xs-p-font;
                    }
                }
            }

            .footer-contact {
                margin-top: 5%;

                h4 {
                    font-size: 4vw;
                    margin-bottom: 5%;
                }

                .footer-contact-body {
                    .footer-contact-content {
                        a {
                            font-size: $xs-p-font;
                        }
                    }
                }

                .footer-follow {
                    .footer-follow-bottom {
                        .download {
                            font-size: $xs-p-font;

                            svg {
                                width: 20px;
                                height: 20px;
                            }
                        }

                    }
                }

            }
        }

        .footer-copyright {
            flex-direction: column;

            p {
                width: 100%;
                font-size: $xs-p-font;
                text-align: center;
                margin: 0;
            }

            .footer-copyright-content {
                width: 100%;
                text-align: center;

                a {
                    font-size: $xs-p-font;
                }
            }
        }

        .amfi {
            flex-wrap: wrap;

            .amfi-card {
                width: calc(100% / 2.2);

                h6 {
                    font-size: 10px;
                }

                p {
                    font-size: 9px;
                }
            }
        }
    }

    /*===============================================================
    Pages
    ===============================================================*/
    .about-page {
        margin-top: 20%;

        .journey {
            .journey-text p {
                font-size: $xs-p-font;
            }
        }

        .vision-and-mission {
            flex-direction: column;

            .vision,
            .mission {
                width: 100%;
                margin: 2% 1%;

                .caption {
                    h5 {
                        font-size: 4vw;
                    }

                    p {
                        font-size: $xs-p-font;
                    }
                }
            }
        }

        .team {
            .team-body {
                .team-card {
                    width: calc(100% / 2.1);

                    .team-caption {
                        h5 {
                            font-size: 3vw;
                        }

                        p {
                            font-size: $xs-p-font;
                        }
                    }

                    button {
                        padding: 0 5%;

                        svg {
                            width: 10px;
                            height: 10px;
                        }
                    }
                }
            }

            .team-description {
                .modal {
                    .modal-content {
                        p {
                            font-size: $xs-p-font;
                        }

                        span {
                            font-size: $xs-p-font;
                        }
                    }
                }
            }
        }

        .awards {

            .carousel .control-prev.control-arrow:before {
                margin-left: -80%;
            }

            .carousel .control-next.control-arrow:before {
                margin-right: -80%;
            }

            .carousel {
                .control-dots {
                    .dot {
                        width: 8px;
                        height: 8px;
                        background: $copper;
                    }
                }

                .carousel-wrapper {
                    .awards-body {
                        margin-bottom: 10%;

                        .awards-card {
                            height: 18vh;

                            h4 {
                                font-size: 2vw;
                                font-weight: normal;
                            }
                        }
                    }
                }
            }
        }

        .media-presence {
            margin-top: 25%;

            .carousel .control-prev.control-arrow:before {
                margin-left: -80%;
            }

            .carousel .control-next.control-arrow:before {
                margin-right: -80%;
            }

            .carousel {
                .control-dots {
                    .dot {
                        display: none;
                    }
                }

                .carousel-wrapper {
                    padding: 0 5%;

                    .media-body {
                        flex-wrap: wrap;
                        margin-bottom: 10%;

                        .media-card {
                            height: 25vh;
                            width: 80%;
                            padding: 5%;
                            margin-bottom: 5%;

                            a {
                                font-size: 3.5vw;
                            }

                            .media-caption {

                                h4,
                                p {
                                    font-size: 4vw;
                                }
                            }
                        }
                    }
                }
            }
        }

        .modal-dialog {
            margin: auto;
        }

        .wise-group {
            .wise-group-body {
                a {
                    width: 100%;
                }
            }
        }

        .imp-line {
            font-size: 3vw;
        }

    }

    .what-we-offer-page {
        margin-top: 20%;

        .what-we-offer {
            .what-we-offer-body {
                .what-card {
                    width: 100%;

                    img {
                        display: block;
                        margin: 0 auto 10% auto;
                        width: 60px;
                        height: 60px;
                    }
                }

                .what-card-text {
                    margin-left: 0;

                    h3 {
                        margin-bottom: 2%;
                    }
                }
            }
        }

        .what-last {
            .what-last-card {
                &:nth-child(even) {
                    flex-direction: column;
                }

                flex-direction: column;

                img {
                    width: 100%;
                }

                .what-last-caption {
                    width: 100%;
                    padding: 5% 0;

                    h4 {
                        margin-bottom: 2%;
                        font-size: 3vw;
                    }

                    p {
                        font-size: $xs-p-font;
                    }
                }
            }

            .what-last-last {
                margin: 0;

                p {
                    font-size: $xs-p-font;
                    margin-bottom: 2%;
                }
            }
        }

    }

    .investment-products {
        .investment-products-body {
            .head {
                p {
                    font-size: $xs-p-font;
                    width: 90%;
                }
            }

            .investment-content {
                .i-c-card {
                    flex-direction: column;

                    &:nth-child(even) {
                        flex-direction: column;

                        .i-c-card-text {
                            width: 100%;
                        }
                    }

                    .i-c-card-img {
                        width: 100%;
                    }

                    .i-c-card-text {
                        width: 100%;
                        padding: 5% 0;

                        h5 {
                            font-size: 3.5vw;
                            font-weight: bold;
                        }

                        p {
                            font-size: $xs-p-font;
                        }
                    }
                }
            }
        }
    }

    .why-us-page {
        margin-top: 25%;

        .why-us-page-body {
            .why-us-page-first {
                p {
                    font-size: $xs-p-font;
                }
            }

            .why-us-page-content {
                .why-us-page-card {
                    width: 100%;

                    &:last-child {
                        .why-us-page-card-caption {
                            ul {
                                li {
                                    font-size: $xs-p-font;
                                    line-height: 200%;
                                }
                            }
                        }
                    }

                    flex-direction: column;

                    .why-us-page-card-img {
                        width: 100%;

                        img {
                            width: 50px;
                            height: 50px;
                            display: block;
                            margin: auto;
                        }
                    }

                    .why-us-page-card-caption {
                        width: 100%;
                        margin-top: 5%;

                        h4 {
                            font-size: 4vw;
                            text-align: center;
                        }

                        p {
                            font-size: $xs-p-font;
                        }
                    }
                }
            }
        }
    }

    .blog {
        margin-top: 20%;

        .blog-body {
            flex-direction: column;

            .blog-body-content {
                width: 100%;

                .blog-card {


                    .blog-card-text {

                        span {
                            font-size: 3vw;
                        }

                        a {
                            font-size: 4vw;
                        }

                        .body {
                            p {
                                font-size: 2.8vw;
                            }
                        }

                        .Read {
                            font-size: 3vw;
                        }
                    }
                }
            }

            .blog-body-text {
                width: 100%;
                padding-left: 0;

                .blog-body-categories {
                    h4 {
                        font-size: 4vw;
                    }

                    &:last-child {
                        h4 {
                            padding-top: 10%;
                        }
                    }

                    .latest {
                        padding: 3% 0;

                        a {
                            font-size: 3vw;
                        }
                    }
                }
            }
        }
    }

    .bcat {
        font-size: 3vw;
        padding: 3% 0;
    }

    .blog-details {
        margin-top: 15%;

        .blog-card-text {
            .date {
                border-radius: 0 0 5px 5px;
                font-size: 3vw;
                color: $copper;
            }

            h1 {
                font-size: 4vw;
            }

            p {
                font-size: 3vw;
                margin-bottom: 3%;

                strong {
                    font-size: 3.5vw;
                }
            }

            ul,
            ol {
                li {
                    font-size: 3vw;

                }
            }
        }
    }

    .career {
        margin-top: 25%;

        .career-content {
            .career-header {
                flex-direction: column;

                .c-h-card {
                    width: 100%;
                    margin-bottom: 5%;

                    .c-h-card-caption {
                        margin-top: 2%;

                        h4 {
                            font-size: 3vw;
                        }

                        p {
                            font-size: 2.5vw;
                        }

                        button {
                            font-size: 2vw;
                        }
                    }
                }
            }
        }

        .career-body {
            .career-body-card {
                width: 100%;

                a {
                    width: 100%;
                    font-size: 2vw;
                }
            }
        }
    }

    .career-programs {
        margin-top: 18%;

        .c-p-categories {
            button {
                font-size: 3vw;
            }
        }

        .c-p-filter {
            p {
                font-size: 3vw;
            }

            select {
                font-size: 2.5vw;
            }
        }

        .c-p-categories-content {
            .c-p-c-c-card {
                margin-bottom: 5%;
                width: 100%;

                h4 {
                    font-size: 5vw;
                }

                h5 {
                    font-size: 4vw;
                }

                ul {
                    li {
                        font-size: $xs-p-font;

                        &:first-child {
                            font-size: 3.5vw;
                        }
                    }
                }

                .c-p-btn {

                    button,
                    a {
                        font-size: $xs-p-font;
                        border: 1px solid $copper;
                    }
                }
            }
        }
    }

    .contact-page {
        margin-top: 20%;

        .locations {

            .location-card,
            .location-card-2 {
                flex-direction: column;
                margin-top: 40%;

                .location-card-text {
                    width: 80%;
                    left: 10%;

                    .location-card-caption {

                        p,
                        a {
                            font-size: 2vw;

                            svg {
                                width: 8px;
                                height: 8px;
                            }
                        }

                        span {
                            font-size: 1.5vw;
                        }

                        .compliance {
                            font-size: 1.5vw;
                        }

                        h3 {
                            font-size: 3vw;
                        }
                    }
                }

                iframe {
                    width: 100%;
                    height: 100%;
                    top: -60%;
                    left: 0;
                    z-index: -1;
                }
            }
        }

        .contact-page-body {
            flex-direction: column;

            .contact-p-left {
                border-right: none;
                padding-right: 0;
                width: 100%;
            }

            .contact-p-right {
                width: 100%;
                margin-top: 5%;

                h5 {
                    font-size: 3vw;
                }

                form {

                    input,
                    textarea,
                    button {
                        font-size: 2vw;
                    }

                }
            }
        }
    }

}

@media (min-width: 481px) and (max-width: $md) and (orientation: landscape) {
    .privacy {
        margin-top: 20%;

        .privacy-body {

            p,
            li {
                font-size: 16px;
            }

            h5 {
                font-size: 18px;
            }
        }
    }
    .navbar {
        .header-info {
            display: none;
        }

        .header {
            .header-links {
                display: none;
            }

            .menu {
                display: block;
            }

            .header-logo {
                img {
                    width: 150%;
                    margin-left: 5%;
                }
            }

            .header-mobile {
                .header-mobile-content {
                    margin-top: 5%;

                    a {
                        font-size: 1.5vw;

                        &:last-child {

                            width: 30%;
                            padding: 2%;

                            &:hover {
                                background: $copper-gradient;
                                color: #fff;
                                transition: .5s all ease;
                                transform: none;

                                &:after {
                                    display: none;
                                }
                            }
                        }

                    }
                }

                .header-mobile-info {
                    .link {
                        ul {
                            li {
                                a {
                                    font-size: 1.5vw;
                                }
                            }
                        }
                    }
                }

            }
        }
    }

    .title {
        h4 {
            font-size: 4vw;
        }
    }

    .banner {

        .carousel-control-next-icon,
        .carousel-control-prev-icon {
            width: 20px;
            height: 20px;
        }

        .carousel {
            height: 50vh;

            .carousel-inner {
                .carousel-item {
                    height: 55vh;
                    margin-top: -5%;

                    h5 {
                        font-size: 3.5vw;
                        width: 50%;
                        top: 38%;
                        right: 5%;
                        line-height: 150%;
                    }

                    img {
                        width: 250px !important;
                        height: 250px;
                        left: 5%;
                        padding: 5%;
                        border-radius: 0;
                    }
                }

            }
        }
    }

    .about {
        flex-wrap: wrap;

        .about-img {
            width: 100%;

            .image-overlay {
                display: none;
            }
        }

        .about-caption {
            width: 100%;
            margin-top: 10%;

            h3 {
                font-size: 3vw;
            }

            p {
                font-size: 2.8vw;
            }
        }
    }

    .investment-philosophy {
        p {
            font-size: $xs-p-font;
        }

        .investment-content {
            flex-wrap: wrap;

            .investment-philosophy-body {
                width: 100% !important;

                .i-p-card {
                    h4 {
                        font-size: $xs-p-font;
                        margin-top: 5%;
                    }

                    .i-img {
                        img {
                            width: 30px;
                            height: 30px;
                        }
                    }
                }
            }

            .child {
                width: 100% !important;

                div {
                    h4 {
                        font-size: 3.5vw;
                    }

                    p {
                        font-size: 2.8vw;
                    }
                }
            }
        }
    }

    .what-we-offer {
        .what-we-offer-body {
            flex-wrap: wrap;

            .what-card {
                width: calc(100%/2.1);
                flex-direction: column;
                // height: 35vh;

                .what-card-img {
                    margin-bottom: 10%;

                    img {
                        margin: auto;
                        display: block;
                        width: 30px;
                        height: 30px;
                    }
                }

                .what-card-text {
                    height: 100%;

                    h3 {
                        font-size: 2.3vw;
                    }

                    p {
                        font-size: 2vw;
                    }

                    a {
                        font-size: 2vw;
                        right: -13%;
                        bottom: -17%;
                        border-radius: 5px 0 0 0;
                    }
                }
            }
        }
    }

    .superior-tech-platform {
        .superior-tech-platform-body {
            .s-t-p-card {
                flex-direction: column;
                width: calc(100% / 2.1);

                img {
                    width: 40px;
                    height: 40px;
                    display: block;
                    margin: 0 auto;
                }

                p {
                    margin-top: 10%;
                    font-size: $xs-p-font;
                    margin-bottom: 0;
                    height: 100%;
                }
            }
        }
    }

    .achievements {
        .achievements-body {
            .a-card {
                width: calc(100% / 3.2);

                svg {

                    width: 35px;
                    height: 35px;
                }

                h4 {
                    font-size: 3.5vw;
                    margin-top: 10%;
                }

                p {
                    font-size: 2vw;
                }
            }
        }
    }

    .home-testimonial {
        .testimonial-body {
            flex-direction: column;
            height: 60vh;
            padding: 5%;

            .testimonial {
                width: 100%;
                margin-top: 5%;

                .source {
                    p {
                        font-size: 2vw;
                    }
                }

                .image {
                    .clip {
                        width: 10px;
                        height: 20px;

                        &::before {
                            height: 10px;
                            width: 5px;
                        }

                        &:after {
                            height: 20px;
                            width: 10px;
                        }
                    }

                    img {
                        width: 50%;
                        right: -40%;
                        position: relative;
                    }
                }

                .quote {
                    width: 20px;
                    height: 20px;
                    font-size: 7vw;
                }

                .source-text {
                    span {
                        font-size: 2vw;
                    }

                    p {
                        font-size: 1.5vw;
                    }
                }
            }
        }
    }

    .home-blog {
        .home-blog-body {
            flex-direction: column;

            .home-blog-card {
                width: 100%;
                margin-bottom: 5%;

                .card-image {
                    img {
                        height: 280px;
                    }
                }
            }
        }
    }

    footer {
        .footer-body {
            flex-direction: column;
            padding: 5%;

            .footer-about,
            .footer-links,
            .footer-contact,
            .footer-map {
                width: 100%;
            }

            .footer-about {
                h2 {
                    font-size: 3vw;
                    margin-bottom: 5%;
                }

                p {
                    font-size: $xs-p-font;
                }
            }

            .footer-links {
                h4 {
                    font-size: 3vw;
                    margin-bottom: 5%;
                }

                .footer-links-content {
                    a {
                        font-size: $xs-p-font;
                    }
                }
            }

            .footer-contact {
                h4 {
                    font-size: 3vw;
                    margin-bottom: 5%;
                }

                .footer-contact-body {
                    .footer-contact-content {
                        a {
                            font-size: $xs-p-font;
                        }
                    }
                }

                .footer-follow {
                    .footer-follow-bottom {
                        .download {
                            font-size: $xs-p-font;

                            svg {
                                width: 20px;
                                height: 20px;
                            }
                        }

                    }
                }

            }
        }

        .footer-copyright {
            flex-direction: column;

            p {
                width: 100%;
                font-size: $xs-p-font;
                text-align: center;
                margin: 0;
            }

            .footer-copyright-content {
                width: 100%;
                text-align: center;

                a {
                    font-size: $xs-p-font;
                }
            }
        }

        .amfi {

            // flex-wrap: wrap;
            .amfi-card {

                // width: calc(100% / 2.2);
                h6 {
                    font-size: 7px;
                }

                p {
                    font-size: 6px;
                }
            }
        }
    }

    /*===============================================================
    Pages
    ===============================================================*/
    .about-page {
        .journey {
            .journey-text p {
                font-size: 2.5vw;
            }
        }

        .main-timeline {
            .timeline {
                .timeline-icon {
                    left: 70%;
                }

                .timeline-content {
                    width: 70%;
                    left: -20%;
                }

                .description {
                    font-size: 2vw;
                }

                &:nth-child(even) {

                    .timeline-icon {
                        left: 10%;
                    }

                    &:after {
                        left: 42%;
                    }

                    .timeline-content {
                        width: 70%;
                        left: 40%;
                    }
                }

                &:after {
                    left: 50%;
                }
            }
        }

        .vision-and-mission {
            flex-direction: column;

            .vision,
            .mission {
                width: 100%;
                margin: 2% 1%;

                .caption {
                    h5 {
                        font-size: 3vw;
                    }

                    p {
                        font-size: 2.5vw;
                    }
                }
            }
        }

        .team {
            .team-body {
                .team-card {
                    width: calc(100% / 2.1);

                    .team-caption {
                        h5 {
                            font-size: 3vw;
                        }

                        p {
                            font-size: $xs-p-font;
                        }
                    }

                    button {
                        padding: 0 5%;

                        svg {
                            width: 10px;
                            height: 10px;
                        }
                    }
                }
            }

            .team-description {
                .modal {
                    .modal-content {
                        span {
                            font-size: 2vw;
                        }

                        p {
                            font-size: 2vw;
                        }
                    }
                }


            }
        }

        .awards {

            .carousel .control-prev.control-arrow:before {
                margin-left: -80%;
            }

            .carousel .control-next.control-arrow:before {
                margin-right: -80%;
            }

            .carousel {
                .control-dots {
                    .dot {
                        width: 8px;
                        height: 8px;
                        background: $copper;
                    }
                }

                .carousel-wrapper {
                    .awards-body {
                        margin-bottom: 10%;

                        .awards-card {
                            height: 25vh;

                            h4 {
                                font-size: 2vw;
                                font-weight: normal;
                            }
                        }
                    }
                }
            }
        }

        .media-presence {
            margin-top: 20%;

            .carousel .control-prev.control-arrow:before {
                margin-left: -80%;
            }

            .carousel .control-next.control-arrow:before {
                margin-right: -80%;
            }

            .carousel {
                .control-dots {
                    .dot {
                        display: none;
                    }
                }

                .carousel-wrapper {
                    padding: 0 5%;

                    .media-body {
                        flex-wrap: wrap;
                        margin-bottom: 10%;

                        .media-card {
                            height: 30vh;
                            width: 80%;
                            padding: 5%;
                            margin-bottom: 5%;

                            a {
                                font-size: 3vw;
                            }

                            .media-caption {

                                h4,
                                p {
                                    font-size: 3vw;
                                }
                            }
                        }
                    }
                }
            }
        }

        .modal-dialog {
            margin: auto;
        }

        .wise-group {
            .wise-group-body {
                a {
                    width: 100%;
                }
            }
        }

        .imp-line {
            font-size: 3vw;
        }

        .home-testimonial {
            background: transparent;

            .carousel .control-prev.control-arrow:before {
                margin-left: -80%;
            }

            .carousel .control-next.control-arrow:before {
                margin-right: -80%;
            }

            .carousel {
                .control-dots {
                    .dot {
                        width: 8px;
                        height: 8px;
                        background: $copper;
                    }
                }
            }

            .testimonial-body {
                margin-bottom: 10%;
                height: 75vh;
            }

        }

    }

    .what-we-offer-page {
        .what-we-offer {
            .what-we-offer-body {
                .what-card {
                    img {
                        display: block;
                        margin: 0 auto 10% auto;
                        width: 40px;
                        height: 40px;
                    }
                }

                .what-card-text {
                    margin-left: 0;

                    h3 {
                        margin-bottom: 2%;
                    }
                }
            }
        }

        .what-last {
            .what-last-card {
                &:nth-child(even) {
                    flex-direction: column;
                }

                flex-direction: column;

                img {
                    width: 100%;
                }

                .what-last-caption {
                    width: 100%;
                    padding: 5% 0;

                    h4 {
                        margin-bottom: 2%;
                        font-size: 3vw;
                    }

                    p {
                        font-size: $xs-p-font;
                    }
                }
            }

            .what-last-last {
                margin: 0;

                p {
                    font-size: $xs-p-font;
                    margin-bottom: 2%;
                }
            }
        }

    }

    .investment-products {
        .investment-products-body {
            .head {
                p {
                    font-size: $xs-p-font;
                    width: 90%;
                }
            }

            .investment-content {
                .i-c-card {
                    flex-direction: column;

                    &:nth-child(even) {
                        flex-direction: column;

                        .i-c-card-text {
                            width: 100%;
                        }
                    }

                    .i-c-card-img {
                        width: 100%;
                    }

                    .i-c-card-text {
                        width: 100%;
                        padding: 5% 0;

                        h5 {
                            font-size: 3.5vw;
                            font-weight: bold;
                        }

                        p {
                            font-size: $xs-p-font;
                        }
                    }
                }
            }
        }
    }

    .why-us-page {
        .why-us-page-body {
            .why-us-page-first {
                p {
                    font-size: $xs-p-font;
                }
            }

            .why-us-page-content {
                .why-us-page-card {

                    &:last-child {
                        .why-us-page-card-caption {
                            ul {
                                li {
                                    font-size: 1.5vw;
                                    line-height: 200%;
                                }
                            }
                        }
                    }

                    flex-direction: column;

                    .why-us-page-card-img {
                        width: 100%;

                        img {
                            width: 50px;
                            height: 50px;
                            display: block;
                            margin: auto;
                        }
                    }

                    .why-us-page-card-caption {
                        width: 100%;
                        margin-top: 5%;
                        height: 100%;

                        h4 {
                            font-size: 2.5vw;
                            text-align: center;
                        }

                        p {
                            font-size: 2vw;
                        }
                    }
                }
            }
        }
    }

    .blog {
        .blog-body {
            flex-direction: column;

            .blog-body-content {
                width: 100%;

                .blog-card {
                    .blog-card-text {
                        span {
                            border-radius: 0 0 5px 5px;
                            font-size: 2vw;
                        }

                        a {
                            font-size: 4vw;
                        }

                        .body {
                            p {
                                font-size: 2.8vw;
                            }
                        }

                        .Read {
                            font-size: 3vw;
                        }
                    }
                }
            }

            .blog-body-text {
                width: 100%;
                padding-left: 0;

                .blog-body-categories {
                    h4 {
                        font-size: 4vw;
                    }

                    &:last-child {
                        h4 {
                            padding-top: 10%;
                        }
                    }

                    .latest {
                        padding: 3% 0;

                        a {
                            font-size: 3vw;
                        }
                    }
                }
            }
        }
    }

    .bcat {
        font-size: 3vw;
        padding: 3% 0;
    }

    .blog-details {
        .blog-card-text {
            h1 {
                font-size: 4vw;
            }

            p {
                font-size: 3vw;
                margin-bottom: 3%;

                strong {
                    font-size: 3.5vw;
                }
            }

            ul,
            ol {
                li {
                    font-size: 3vw;
                    margin-bottom: 3%;
                }
            }
        }
    }

    .career {
        .career-content {

            .career-header {
                flex-direction: column;

                .c-h-card {
                    width: 100%;
                    margin-bottom: 5%;

                    .c-h-card-caption {
                        margin-top: 2%;

                        h4 {
                            font-size: 3vw;
                        }

                        p {
                            font-size: 2.5vw;
                        }

                        button {
                            font-size: 2vw;
                        }
                    }
                }
            }
        }

        .career-body {
            .career-body-card {
                width: 100%;

                a {
                    width: 100%;
                    font-size: 2vw;
                }
            }
        }
    }

    .career-programs {
        .c-p-categories {
            button {
                font-size: 3vw;
            }
        }

        .c-p-filter {
            p {
                font-size: 3vw;
            }

            select {
                font-size: 2.5vw;
            }
        }

        .c-p-categories-content {
            .c-p-c-c-card {
                width: calc(100% / 2.1);

                h4 {
                    font-size: 2.5vw;
                }

                h5 {
                    font-size: 3.5vw;
                }

                ul {
                    li {
                        font-size: 2vw;

                        &:first-child {
                            font-size: 2vw;
                        }
                    }
                }

                .c-p-btn {

                    button,
                    a {
                        font-size: 2vw;
                        border: 1px solid $copper;
                    }
                }
            }
        }
    }

    .contact-page {
        .locations {

            .location-card,
            .location-card-2 {
                flex-direction: column;
                margin-top: 40%;

                .location-card-text {
                    width: 80%;
                    left: 10%;

                    .location-card-caption {

                        p,
                        a {
                            font-size: 2vw;

                            svg {
                                width: 8px;
                                height: 8px;
                            }
                        }

                        span {
                            font-size: 1.5vw;
                        }

                        .compliance {
                            font-size: 1.5vw;
                        }

                        h3 {
                            font-size: 3vw;
                        }
                    }
                }

                iframe {
                    width: 100%;
                    height: 100%;
                    top: -60%;
                    left: 0;
                    z-index: -1;
                }
            }
        }

        .contact-page-body {
            flex-direction: column;

            .contact-p-left {
                border-right: none;
                padding-right: 0;
                width: 100%;
            }

            .contact-p-right {
                width: 100%;
                margin-top: 5%;

                h5 {
                    font-size: 3vw;
                }

                form {

                    input,
                    textarea,
                    button {
                        font-size: 2vw;
                    }

                }
            }
        }
    }
}

@media (min-width: 481px) and (max-width: $md) and (orientation: portrait) {
    .privacy {
        margin-top: 20%;

        .privacy-body {

            p,
            li {
                font-size: 16px;
            }

            h5 {
                font-size: 18px;
            }
        }
    }
    .title,
    .page-title {
        h4 {
            font-size: 4vw;
            margin-bottom: 5%;
        }
    }


    .navbar {
        .header-info {
            display: none;
        }

        .header {
            .header-links {
                display: none;
            }

            .menu {
                display: block;
            }

            .header-logo {
                img {
                    width: 150%;
                    margin-left: 5%;
                }
            }
        }
    }

    .banner {

        .carousel-control-next-icon,
        .carousel-control-prev-icon {
            width: 20px;
            height: 20px;
        }

        .carousel {
            height: 40vh;

            .carousel-inner {
                .carousel-item {
                    height: 40vh;

                    h5 {
                        font-size: 4vw;
                        width: 40%;
                        top: 40%;
                        line-height: 150%;
                    }

                    img {
                        width: 180px !important;
                        height: 180px;
                        left: 5%;
                        padding: 5%;
                        border-radius: 0;
                    }
                }

            }
        }
    }

    .about {
        flex-wrap: wrap;

        .about-img {
            width: 100%;

            .image-overlay {
                display: none;
            }
        }

        .about-caption {
            width: 100%;
            margin-top: 10%;

            h3 {
                font-size: 3vw;
            }

            p {
                font-size: 2.8vw;
            }
        }
    }

    .investment-philosophy {
        p {
            font-size: $xs-p-font;
        }

        .investment-content {
            flex-wrap: wrap;

            .investment-philosophy-body {
                width: 100% !important;

                .i-p-card {
                    h4 {
                        font-size: $xs-p-font;
                        margin-top: 5%;
                    }

                    .i-img {
                        img {
                            width: 30px;
                            height: 30px;
                        }
                    }
                }
            }

            .child {
                width: 100% !important;

                div {
                    h4 {
                        font-size: 3.5vw;
                    }

                    p {
                        font-size: 2.8vw;
                    }
                }
            }
        }
    }

    .what-we-offer {
        .what-we-offer-body {
            flex-wrap: wrap;

            .what-card {
                width: calc(100%/2.1);
                flex-direction: column;
                // height: 35vh;

                .what-card-img {
                    margin-bottom: 10%;

                    img {
                        margin: auto;
                        display: block;
                        width: 30px;
                        height: 30px;
                    }
                }

                .what-card-text {
                    height: 100%;

                    h3 {
                        font-size: 2.3vw;
                    }

                    p {
                        font-size: 2vw;
                    }

                    a {
                        font-size: 2vw;
                        right: -13%;
                        bottom: -17%;
                        border-radius: 5px 0 0 0;
                    }
                }
            }
        }
    }

    .superior-tech-platform {
        .superior-tech-platform-body {
            .s-t-p-card {
                flex-direction: column;
                width: calc(100% / 2.1);

                img {
                    width: 40px;
                    height: 40px;
                    display: block;
                    margin: 0 auto;
                }

                p {
                    margin-top: 10%;
                    font-size: $xs-p-font;
                    margin-bottom: 0;
                    height: 100%;
                }
            }
        }
    }

    .achievements {
        .achievements-body {
            .a-card {
                width: calc(100% / 3.2);

                svg {

                    width: 35px;
                    height: 35px;
                }

                h4 {
                    font-size: 3.5vw;
                    margin-top: 10%;
                }

                p {
                    font-size: 2vw;
                }
            }
        }
    }

    .home-testimonial {
        padding: 5% 0;

        .carousel {
            padding: 0 3%;

            .carousel-slider {
                .control-arrow {
                    height: 80%;
                }
            }

            .control-next.control-arrow:before {
                border-top: 8px solid transparent;
                border-left: 8px solid $copper;
                border-bottom: 8px solid transparent;
            }

            .control-prev.control-arrow:before {
                border-top: 8px solid transparent;
                border-right: 8px solid $copper;
                border-bottom: 8px solid transparent;
            }
        }

        .testimonial-body {
            flex-direction: column;
            height: 80%;
            padding: 0 5%;
            margin-bottom: 10%;

            .testimonial {
                width: 100%;
                margin-top: 5%;
                height: 90vh;

                &:nth-child(2) {
                    display: none;
                }

                .source {
                    p {
                        font-size: 2.5vw;
                        margin-top: 7%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 13;
                        /* number of lines to show */
                        line-clamp: 13;
                        -webkit-box-orient: vertical;
                    }
                }

                .image {

                    .clip {
                        width: 10px;
                        height: 20px;

                        &::before {
                            height: 10px;
                            width: 5px;
                        }

                        &:after {
                            height: 20px;
                            width: 10px;
                        }
                    }

                    img {
                        width: 50%;
                        right: -40%;
                        position: relative;
                    }
                }

                .quote {
                    width: 20px;
                    height: 20px;
                    font-size: 7vw;
                }

                .source-text {
                    span {
                        font-size: 3vw;
                    }

                    p {
                        font-size: 2.5vw;
                    }
                }
            }
        }
    }

    .home-blog {
        .home-blog-body {
            flex-direction: column;

            .home-blog-card {
                width: 100%;
                margin-bottom: 5%;

                .card-image {

                    img {
                        height: 250px;

                    }
                }
            }
        }
    }

    footer {

        .footer-body {
            flex-direction: column;
            padding: 5%;

            .footer-about,
            .footer-links,
            .footer-contact,
            .footer-map {
                width: 100%;
            }

            .footer-about {
                h2 {
                    font-size: 3vw;
                    margin-bottom: 5%;
                }

                p {
                    font-size: $xs-p-font;
                }
            }

            .footer-links {
                h4 {
                    font-size: 3vw;
                    margin-bottom: 5%;
                }

                .footer-links-content {
                    a {
                        font-size: $xs-p-font;
                    }
                }
            }

            .footer-contact {
                h4 {
                    font-size: 3vw;
                    margin-bottom: 5%;
                }

                .footer-contact-body {
                    .footer-contact-content {
                        a {
                            font-size: $xs-p-font;
                        }
                    }
                }

                .footer-follow {
                    .footer-follow-bottom {
                        .download {
                            font-size: $xs-p-font;

                            svg {
                                width: 20px;
                                height: 20px;
                            }
                        }

                    }
                }

            }
        }

        .footer-copyright {
            flex-direction: column;

            p {
                width: 100%;
                font-size: $xs-p-font;
                text-align: center;
                margin: 0;
            }

            .footer-copyright-content {
                width: 100%;
                text-align: center;

                a {
                    font-size: $xs-p-font;
                }
            }
        }

        .amfi {

            // flex-wrap: wrap;
            .amfi-card {

                // width: calc(100% / 2.2);
                h6 {
                    font-size: 7px;
                }

                p {
                    font-size: 6px;
                }
            }
        }
    }

    /*===============================================================
    Pages
    ===============================================================*/
    .about-page {
        .journey {
            .journey-text p {
                font-size: 2.5vw;
            }
        }

        .main-timeline {
            .timeline {
                .timeline-icon {
                    left: 70%;
                }

                .timeline-content {
                    width: 70%;
                    left: -20%;
                }

                .description {
                    font-size: 2vw;
                }

                &:nth-child(even) {

                    .timeline-icon {
                        left: 10%;
                    }

                    &:after {
                        left: 42%;
                    }

                    .timeline-content {
                        width: 70%;
                        left: 40%;
                    }
                }

                &:after {
                    left: 50%;
                }
            }
        }

        .vision-and-mission {
            flex-direction: column;

            .vision,
            .mission {
                width: 100%;
                margin: 2% 1%;

                .caption {
                    h5 {
                        font-size: 3vw;
                    }

                    p {
                        font-size: 2.5vw;
                    }
                }
            }
        }

        .team {
            .team-body {
                .team-card {
                    width: calc(100% / 2.1);

                    .team-caption {
                        h5 {
                            font-size: 3vw;
                        }

                        p {
                            font-size: $xs-p-font;
                        }
                    }

                    button {
                        padding: 0 5%;

                        svg {
                            width: 10px;
                            height: 10px;
                        }
                    }
                }
            }

            .team-description {
                .modal {
                    .modal-content {
                        span {
                            font-size: 2vw;
                        }

                        p {
                            font-size: 2vw;
                        }
                    }
                }


            }
        }


        .awards {

            .carousel .control-prev.control-arrow:before {
                margin-left: -80%;
            }

            .carousel .control-next.control-arrow:before {
                margin-right: -80%;
            }

            .carousel {
                .control-dots {
                    .dot {
                        width: 8px;
                        height: 8px;
                        background: $copper;
                    }
                }

                .carousel-wrapper {
                    .awards-body {
                        margin-bottom: 10%;

                        .awards-card {
                            height: 25vh;

                            h4 {
                                font-size: 2vw;
                                font-weight: normal;
                            }
                        }
                    }
                }
            }
        }

        .media-presence {
            margin-top: 20%;

            .carousel .control-prev.control-arrow:before {
                margin-left: -80%;
            }

            .carousel .control-next.control-arrow:before {
                margin-right: -80%;
            }

            .carousel {
                .control-dots {
                    .dot {
                        display: none;
                    }
                }

                .carousel-wrapper {
                    padding: 0 5%;

                    .media-body {
                        flex-wrap: wrap;
                        margin-bottom: 10%;

                        .media-card {
                            height: 30vh;
                            width: 80%;
                            padding: 5%;
                            margin-bottom: 5%;

                            a {
                                font-size: 3vw;
                            }

                            .media-caption {

                                h4,
                                p {
                                    font-size: 3vw;
                                }
                            }
                        }
                    }
                }
            }
        }


        .modal-dialog {
            margin: auto;
        }

        .wise-group {
            .wise-group-body {
                a {
                    width: 100%;
                }
            }
        }

        .imp-line {
            font-size: 3vw;
        }

        .home-testimonial {
            background: transparent;

            .carousel .control-prev.control-arrow:before {
                margin-left: -80%;
            }

            .carousel .control-next.control-arrow:before {
                margin-right: -80%;
            }

            .carousel {
                .control-dots {
                    .dot {
                        width: 8px;
                        height: 8px;
                        background: $copper;
                    }
                }
            }

            .testimonial-body {
                margin-bottom: 10%;
                height: 75vh;
            }

        }

    }

    .what-we-offer-page {
        .what-we-offer {
            .what-we-offer-body {
                .what-card {
                    img {
                        display: block;
                        margin: 0 auto 10% auto;
                        width: 40px;
                        height: 40px;
                    }
                }

                .what-card-text {
                    margin-left: 0;

                    h3 {
                        margin-bottom: 2%;
                    }
                }
            }
        }

        .what-last {
            .what-last-card {
                &:nth-child(even) {
                    flex-direction: column;
                }

                flex-direction: column;

                img {
                    width: 100%;
                }

                .what-last-caption {
                    width: 100%;
                    padding: 5% 0;

                    h4 {
                        margin-bottom: 2%;
                        font-size: 3vw;
                    }

                    p {
                        font-size: $xs-p-font;
                    }
                }
            }

            .what-last-last {
                margin: 0;

                p {
                    font-size: $xs-p-font;
                    margin-bottom: 2%;
                }
            }
        }

    }

    .investment-products {
        .investment-products-body {
            .head {
                p {
                    font-size: $xs-p-font;
                    width: 90%;
                }
            }

            .investment-content {
                .i-c-card {
                    flex-direction: column;

                    &:nth-child(even) {
                        flex-direction: column;

                        .i-c-card-text {
                            width: 100%;
                        }
                    }

                    .i-c-card-img {
                        width: 100%;
                    }

                    .i-c-card-text {
                        width: 100%;
                        padding: 5% 0;

                        h5 {
                            font-size: 3.5vw;
                            font-weight: bold;
                        }

                        p {
                            font-size: $xs-p-font;
                        }
                    }
                }
            }
        }
    }

    .why-us-page {
        .why-us-page-body {
            .why-us-page-first {
                p {
                    font-size: $xs-p-font;
                }
            }

            .why-us-page-content {
                .why-us-page-card {

                    &:last-child {
                        .why-us-page-card-caption {
                            ul {
                                li {
                                    font-size: 1.5vw;
                                    line-height: 200%;
                                }
                            }
                        }
                    }

                    flex-direction: column;

                    .why-us-page-card-img {
                        width: 100%;

                        img {
                            width: 50px;
                            height: 50px;
                            display: block;
                            margin: auto;
                        }
                    }

                    .why-us-page-card-caption {
                        width: 100%;
                        margin-top: 5%;
                        height: 100%;

                        h4 {
                            font-size: 2.5vw;
                            text-align: center;
                        }

                        p {
                            font-size: 2vw;
                        }
                    }
                }
            }
        }
    }

    .blog {
        .blog-body {
            flex-direction: column;

            .blog-body-content {
                width: 100%;

                .blog-card {
                    .blog-card-text {
                        span {
                            border-radius: 0 0 5px 5px;
                            font-size: 2vw;
                        }

                        a {
                            font-size: 4vw;
                        }

                        .body {
                            p {
                                font-size: 2.8vw;
                            }
                        }

                        .Read {
                            font-size: 3vw;
                        }
                    }
                }
            }

            .blog-body-text {
                width: 100%;
                padding-left: 0;

                .blog-body-categories {
                    h4 {
                        font-size: 4vw;
                    }

                    &:last-child {
                        h4 {
                            padding-top: 10%;
                        }
                    }

                    .latest {
                        padding: 3% 0;

                        a {
                            font-size: 3vw;
                        }
                    }
                }
            }
        }
    }

    .bcat {
        font-size: 3vw;
        padding: 3% 0;
    }

    .blog-details {
        .blog-card-text {
            h1 {
                font-size: 4vw;
            }

            p {
                font-size: 3vw;
                margin-bottom: 3%;

                strong {
                    font-size: 3.5vw;
                }
            }

            ul,
            ol {
                li {
                    font-size: 3vw;
                    margin-bottom: 3%;
                }
            }
        }
    }

    .career {
        .career-content {

            .career-header {
                flex-direction: column;

                .c-h-card {
                    width: 100%;
                    margin-bottom: 5%;

                    .c-h-card-caption {
                        margin-top: 2%;

                        h4 {
                            font-size: 3vw;
                        }

                        p {
                            font-size: 2.5vw;
                        }

                        button {
                            font-size: 2vw;
                        }
                    }
                }
            }
        }

        .career-body {
            .career-body-card {
                width: 100%;

                a {
                    width: 100%;
                    font-size: 2vw;
                }
            }
        }
    }

    .career-programs {
        .c-p-categories {
            button {
                font-size: 3vw;
            }
        }

        .c-p-filter {
            p {
                font-size: 3vw;
            }

            select {
                font-size: 2.5vw;
            }
        }

        .c-p-categories-content {
            .c-p-c-c-card {
                width: calc(100% / 2.1);

                h4 {
                    font-size: 2.5vw;
                }

                h5 {
                    font-size: 3.5vw;
                }

                ul {
                    li {
                        font-size: 2vw;

                        &:first-child {
                            font-size: 2vw;
                        }
                    }
                }

                .c-p-btn {

                    button,
                    a {
                        font-size: 2vw;
                        border: 1px solid $copper;
                    }
                }
            }
        }
    }

    .contact-page {
        .locations {

            .location-card,
            .location-card-2 {
                flex-direction: column;
                margin-top: 40%;

                .location-card-text {
                    width: 80%;
                    left: 10%;

                    .location-card-caption {

                        p,
                        a {
                            font-size: 2vw;

                            svg {
                                width: 8px;
                                height: 8px;
                            }
                        }

                        span {
                            font-size: 1.5vw;
                        }

                        .compliance {
                            font-size: 1.5vw;
                        }

                        h3 {
                            font-size: 3vw;
                        }
                    }
                }

                iframe {
                    width: 100%;
                    height: 100%;
                    top: -60%;
                    left: 0;
                    z-index: -1;
                }
            }
        }

        .contact-page-body {
            flex-direction: column;

            .contact-p-left {
                border-right: none;
                padding-right: 0;
                width: 100%;
            }

            .contact-p-right {
                width: 100%;
                margin-top: 5%;

                h5 {
                    font-size: 3vw;
                }

                form {

                    input,
                    textarea,
                    button {
                        font-size: 2vw;
                    }

                }
            }
        }
    }

}

@media (min-width: 768px) and (max-width: $lg) and (orientation: landscape) {
    .privacy {
        margin-top: 10%;

        .privacy-body {

            p,
            li {
                font-size: 16px;
            }

            h5 {
                font-size: 18px;
            }
        }
    }
}

@media (min-width: 768px) and (max-width: $lg) and (orientation: portrait) {
    .privacy {
        margin-top: 10%;

        .privacy-body {

            p,
            li {
                font-size: 16px;
            }

            h5 {
                font-size: 18px;
            }
        }
    }
    .footer {
        .footer-body {
            .amfi {

                // flex-wrap: wrap;
                .amfi-card {

                    // width: calc(100% / 2.2);
                    h6 {
                        font-size: 7px;
                    }

                    p {
                        font-size: 6px;
                    }
                }
            }
        }

    }
}

@media (min-width: 1025px) and (max-width: $xl) {}