// padding
$padding: 5% 5% 5% 5%;
$card-padding: 3%;
$text-font-size: 1.1vw;
$title-font-size: 1.3vw;
$margin-right: 5%;
$card-margin: 1%;
$bolder-font: 800;

// font-family

$boxShadow: 2px 2px 5px rgba(0, 0, 0, 0.3);

//font -sizes
$xs-p-font: 3vw;

// colors

$black: #101314;
$white: #fffffa;
$copper: #b76c41;

// gradients

$copper-gradient: linear-gradient(90deg, rgba(183, 108, 65, 1) 0%, rgba(212, 155, 122, 1) 100%);
$copper-transparent-gradient: linear-gradient(90deg, rgba(183, 108, 65, .4) 0%, rgba(212, 155, 122, .4) 100%);